.serenescape-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 3%;
}

/* .serenescape-logo {
  width: 798px;
  height: 798px;
  background-image: url('./assets/serenescape-logo.svg');
  position: absolute;
  left: -399px;
  top: -399px;
  z-index: -1;
} */

.serenescape-heading {
  /* margin-left: 40%; */
  margin-top: 7%;
  text-align: left;
}

.serenescape-heading h1, .serenescape-heading h2 {
  font-family: 'Hiragino-Sans';
  text-transform: uppercase;
}

.serenescape-heading h1 {
  font-size: 7rem;
  letter-spacing: 1rem;
  border-bottom: 1px #FFF solid;
}

.serenescape-heading h2 {
  font-size: 5rem;
  letter-spacing: 0rem;
}

.serenescape-intro {
  margin-top: 10%;
  /* margin-left: 25%; */
  margin-left: 3%;
  margin-right: 3%;
  display: flex;
}

.intro-copy {
  margin-top: 7%;
}

.serenescape-premium {
  margin-top: 7%;
}

.premium-info {
  display: flex;
  margin-top: 3%;
}

.premium-copy {
  margin-left: 3%;
}

.premium-copy h4 {
  font-family: 'Hiragino-Sans';
  font-size: 2rem;
  text-transform: uppercase;
}

.premium-copy ul {
  margin-left: 7%;
  font-size: 1.7rem;
}

.premium-copy ul li {
  margin-top: 3%;
}

.intro-copy p, .premium-copy p {
  font-size: 2.5rem;
  margin-bottom: 5rem;
}

.relax-screenshot img {
  max-width: 242px;
}

.widget-screenshot img {
  max-width: 300px;
}

.serenescape-premium h3 {
  font-family: 'Hiragino-Sans';
  font-size: 3.5rem;
  text-transform: uppercase;
}

@media only screen and (min-width: 900px) {
  .serenescape-logo {
    width: 798px;
    height: 798px;
    background-image: url('./assets/serenescape-logo.svg');
    position: absolute;
    left: -399px;
    top: -399px;
    z-index: -1;
  }
  
  .serenescape-heading {
    margin-left: 40%;
    margin-top: 7%;
    text-align: right;
  }
}

/* .relax-screenshot img {
  max-width: 242px;
}

div.serenescape-logo {
  width: 798px;
  height: 798px;
  background-image: url('./assets/serenescape-logo.svg');
  position: absolute;
  left: -399px;
  top: -399px;
}

div.serenescape-heading {
  color: #FFF;
  float: right;
  clear: both;
  margin-top: 3rem;
  margin-right: 3%;
  text-align: right;
}

div.serenescape-heading h1 {
  font-size: 8rem;
  font-family: 'Hiragino-Sans';
  text-transform: uppercase;
}

div.serenescape-heading h2 {
  font-size: 5rem;
  font-family: 'Hiragino-Sans';
  text-transform: uppercase;
}

div.serenescape-intro {
  display: flex;
  width: 70%;
  float: right;
  clear: both;
  margin-top: 10rem;
  margin-right: 3%;
}

div.intro-copy {
  margin-top: 3%;
}

div.intro-copy p {
  font-size: 2.5rem;
  padding: 1.5rem;
}

div.intro-copy > .download-app {
  float: right;
  margin-right: 3%;
  color: #FFF;
} */
