.privacy-wrapper {
    margin: 3%;
}

.privacy-wrapper h1 {
    font-family: 'Montserrat';
    font-size: 2.0rem;
    margin-bottom: 3%;
}

.privacy-wrapper h2 {
    font-family: 'Montserrat';
    font-size: 1.7rem;
    margin-top: 3%;
    margin-bottom: 2%;
}

.privacy-wrapper a {
    color: #FFF;
}